<template>
  <div class="abogados">
    <!--section class="text-center" id="about">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-8">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h2 class="text-bdlg mb-4 abogadostitulo">{{headerTitle[lang]}}</h2>
          <p class="text-bdlg">
            {{headerSubtitle[lang]}}
          </p>
        </div>
      </div>
    </div>
  </section-->

    <section class="pt-5">
      <div class="row justify-content-center text-justify w-full h-min-full">
        <div
          class="col-md-4 col-lg-6 md:h-100 bg-bdlg p-5 text-white text-center"
        >
          <h2 class="mt-5">{{ headerTitle[lang] }}</h2>
          <p>{{ headerSubtitle[lang] }}</p>
        </div>
        <div
          class="col-md-8 col-lg-6 h-full px-0 bg-white bg-opacity-255 md:pt-5"
        >
          <a
            class="row gx-0 mb-5 mb-lg-0 justify-content-center bloqueabogado"
            v-for="(a, indice) in abogados"
            :key="a.nombre"
            :href="'#/abogados/' + a._id"
          >
            <div class="col-md-6" v-if="indice % 2 == 0 && !esMovil">
              <img
                class="img-fluid img-abogado"
                :src="a.fotos[0]"
                :alt="a.nombre"
              />
            </div>
            <div class="col-md-6">
              <div class="bg-dark text-left text-lg-center h-100 project">
                <div class="d-flex h-100">
                  <div
                    class="project-text w-100 my-auto p-4 presentador-abogado"
                  >
                    <h4 class="text-white">{{ a.nombre }}</h4>
                    <p class="mb-0 text-white-50">{{ a.email }}</p>
                    <hr class="d-none d-lg-block mb-0 ms-0" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="indice % 2 != 0 || esMovil">
              <img
                class="img-fluid img-abogado"
                :src="a.fotos[0]"
                :alt="a.nombre"
              />
            </div>
          </a>
        </div>
      </div>
    </section>

    <!--section class="projects-section" id="projects">

    <div class="w-full px-2 px-lg-2">

      <a class="row gx-0 mb-5 mb-lg-0 justify-content-center bloqueabogado" v-for="(a,indice) in abogados" :key="a.nombre" :href="'#/abogados/'+lang+'/'+a._id">
        <div class="col-lg-6" v-if="indice%2==0">
          <img class="img-fluid img-abogado" :src="a.fotos[0]" :alt="a.nombre" />
        </div>
        <div class="col-lg-6">
          <div class="bg-black text-center h-100 project">
            <div class="d-flex h-100">
              <div class="project-text w-100 my-auto text-center text-lg-left">
                <h4 class="text-white">{{a.nombre}}</h4>
                <p class="mb-0 text-white-50">{{a.email}}</p>
                <hr class="d-none d-lg-block mb-0 ms-0" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" v-if="indice%2!=0">
          <img class="img-fluid img-abogado" :src="a.fotos[0]" :alt="a.nombre" />
        </div>
      </a>
    </div>

  </section-->
  </div>
</template>
<style>
@media screen and (max-width: 768px) {
  .presentador-abogado {
    min-height: 120px;
    padding: 2em !important;
    text-align: center;
    border: 2px solid #cccccc;
  }
}
</style>
<script>
import axios from "axios";
export default {
  name: "Abogados",
  //props: ['empresa', 'lang'],
  props: {
    lang: {
      type: String,
      default: "es",
    },
  },
  data() {
    return {
      cdn: "https://app.haakon.mx:8083/files/",
      esMovil: window.innerWidth < 768,
      headerTitle: {
        es: "Nuestos abogados",
        en: "Our attorneys",
      },
      headerSubtitle: {
        es: "Lo que nos mueve son nuestros clientes",
        en: "Our clients drive us",
      },
      abogados: [
        {
          nombre: "Abogado 1",
          email: "hola@mundo",
          fotos: ["https://api.lorem.space/image/face?w=150&h=150"],
        },
        {
          nombre: "Abogado 2",
          email: "hola2@mundo",
          fotos: ["https://api.lorem.space/image/face?w=151&h=151"],
        },
        {
          nombre: "Abogado 3",
          email: "hola3@mundo",
          fotos: ["https://api.lorem.space/image/face?w=152&h=152"],
        },
        {
          nombre: "Abogado 4",
          email: "hola3@mundo",
          fotos: ["https://api.lorem.space/image/face?w=153&h=153"],
        },
      ],
    };
  },
  async mounted() {
    try {
      const url = "https://app.haakon.mx:8083/rvrs/readbypass/usuarioweb";
      const cdn = this.cdn;
      const abogadosDespacho = await axios.post(
        url,
        {},
        { headers: { "wst-appkey": "bdlg.mx" } }
      );
      console.log("abogadosDespacho?", abogadosDespacho);
      this.abogados = abogadosDespacho.data.map((a) => {
        a.fotos = [cdn + a.foto];
        return a;
      });
    } catch (e) {
      console.error(e);
    } //*/
    console.log("Abogados?", JSON.stringify(this.abogados));
    this.$forceUpdate();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.abogados {
  background-color: white;
  color: black;
  width: 100vw;
  min-height: 100vh;
}

.helloimg {
  display: block;
  padding: 20px;
  max-width: 30vw;
}

.bloqueabogado {
  border-radius: 5px;
}

.bloqueabogado img {
  width: 100%;
}

.abogados a {
  text-decoration: none;
}
</style>
