<template>
  <div class="hello">
    <div class="abogadodisplay">
      <div
        class="
          container-fluid
          px-1 px-lg-5
          d-flex
          h-100
          align-items-center
          justify-content-center
        "
      >
        <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-sm-4 half1">
            <img :src="abogado.imagen" />
            <div>
              <p>&nbsp;</p>
              <h1>{{ abogado.nombre }}</h1>
              <p>{{ abogado.rol }}</p>
              <hr />
              <div class="text-medium">
                <p>Tel: {{ abogado.telefono }}</p>
                <p>
                  <a :href="'mailto:' + abogado.email">{{ abogado.email }}</a>
                </p>
                <p><a>Descargar VCARD</a></p>
              </div>
              <div>
                <hr />
                <p v-if="lang == 'es'">Especialidades</p>
                <p v-else>Specialties</p>
                <ul>
                  <li
                    v-for="(e, indice) in abogado.especialidades"
                    :key="indice"
                  >
                    <a :href="'#/areas/' + e._id">{{ e.titulo[lang] }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-8 half2 text-bdlg text-left py-4">
            <p v-html="abogado.descripcion1"></p>
            <p v-html="abogado.descripcion2"></p>
            <p v-html="abogado.descripcion3"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.abogadodisplay {
  width: 100vw;
  background-color: white;
  padding-top: 5em;
}

.half1,
.half2 {
  padding-top: 1em;
  min-height: calc(100vh - 12.5em);
}

.half1 {
  color: white;
  background-color: #b6b6b6;
  padding-left: 2em;
  padding-right: 2em;
}

.half1 img {
  width: 100%;
  padding-top: 0.7em;
}

.half1 .text-medium,
.half1 a {
  color: #535d5d;
}

.half1 p {
  margin-bottom: 0.1em;
}

.half1 h1 {
  font-size: 1.5em;
}

.half2 {
  padding-left: 4em;
  padding-right: 4em;
}
</style>
<script>
import axios from "axios";
import Api from "@/components/Api";
let tratarTextoParrafo = function (str = "") {
  str = str.replace(/\n/g, "<br />");
  str = "<b>" + str.replace("<br />", "</b><br />");
  return str;
};
let tratarTextoLista = function (str = "") {
  return "<ul><li>" + str.replace(/\n/g, "</li><li>") + "</li></ul>";
};
export default {
  name: "Abogado",
  props: {
    id: {
      type: {
        String,
        default: "0",
      },
    },
  },
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      url: "https://app.haakon.mx:8083/rvrs",
      abogado: {
        nombre: "Abogado Maestro 117",
        descripcion: "",
        imagen: "",
      },
    };
  },
  async mounted() {
    const url = this.url;
    const cdn = "https://app.haakon.mx:8083/files/";
    const id = this.id;
    const lang = this.lang;
    try {
      const abogados = (
        await axios.post(
          url + "/readbypass/usuarioweb",
          {},
          { headers: { "wst-appkey": "bdlg.mx" } }
        )
      ).data;
      console.log("abogados?", id, abogados);
      let a = abogados.filter((j) => j._id == id)[0];
      console.log("abogado?", a);
      this.abogado = {
        nombre: a.nombre,
        imagen: cdn + a.foto,
        rol: a.tipo || "Asociado",
        email: a.email,
        telefono: a.telefono,
        descripcion1: tratarTextoParrafo(a.copy[lang]["resumen"]),
        descripcion2: tratarTextoLista(a.copy[lang]["detalles"]),
        especialidades:
          typeof a.copy[lang]["especialidades"] == "string"
            ? a.copy[lang]["especialidades"].split("\n")
            : a.copy[lang]["especialidades"],
      };
      let areas = (
        await axios.post(
          url + "/readbypass/areaweb",
          {},
          { headers: { "wst-appkey": "bdlg.mx" } }
        )
      ).data.filter((a) => a.abogados && a.abogados.indexOf(id) >= 0);
      console.log("areas?", areas);
      this.abogado.especialidades = areas;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
